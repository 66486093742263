import { reactive } from "vue";

const theme = reactive({
  agentLogo: "kollosche-footer-logo.svg",
  devLogo: "eternity-header-logo.svg",
  apiUrl: "https://api.metaluxe.com.au/",
  bunnyApiUrl: "https://api.bunny.net/",
  accessKey: "ec558f34-7538-4b7e-b822-179253457e6b93932c0d-eae6-47b4-b444-1ac035b8ff7f",
  streamToken: "1e310d16-563b-4418-959f-033e6c35d5de",
  videoLibraryId: "61776",
  collectionId: "fefc001b-4708-4c5e-b267-2a4d3183fa94",
  streamCdnUrl: "https://vz-25b35546-852.b-cdn.net",
  streamApiKey: "047721e1-5e16-415a-884583a9834e-c0cb-4a0f",
  storageApiPassword: "c151bf84-add5-4f28-97989b1a616c-a8c4-449c",
  cdnUrl: "https://eternity.metaluxe.com.au",
  devFolder: "/eternity",
  videosIcon: "/img/ui-theme/videos-icon.svg",
  rendersIcon: "/img/ui-theme/renders-icon.svg",
  rangeIcon: "/img/ui-theme/range-icon.svg",
  panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
  floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
  dashboardMp4: "theme/dashboard/4K_dashboard.mp4",
  dashboardWebm: "theme/dashboard/4K_dashboard.webm",
  assetsMp4: "theme/dashboard/4K_assets.mp4",
  assetsWebm: "theme/dashboard/4K_assets.webm",
  
  // top level menu
  assetsMenuOrder: [
    '3-bedroom-study-type-03',
    'half-floor-apartment',
    'middle-apartment',
    'amenities',
    'exterior'
  ],

  // asset level menu
  bed3MenuOrder: [
    'videos',
    'virtual-tour',
    'floorplans'
  ],

  halfMenuOrder: [
    'videos',
    'virtual-tour',
    'floorplans'
  ],

  middleMenuOrder: [
    'videos',
    'virtual-tour',
    'floorplans'
  ],

  amenitiesMenuOrder: [
    'videos',
    'virtual-tour'
  ],

  exteriorMenuOrder: [
    'videos'
  ],
  
  // assets menu
  bed3Asset: {
    videos: [ 0, 1 ],
    videosName: [
      'Walkthrough',
      'Cinematic'
    ],
    panoramas: [ 0 ],
    panoramasName: [
      '3 Bed Study Type 03'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/J5H8vMoCl'
    ],
    floorplans: [ 0 ],
    floorplansName: [
      '3 Bed Study Type 03'
    ],
    floorplansUrl: [
      ''
    ]
  },

  halfAsset: {
    videos: [ 0, 1 ],
    videosName: [
      'Cinematic',
      'Walkthrough'
    ],
    panoramas: [ 0 ],
    panoramasName: [
      'Half Floor Apartment'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/wTz0tjrcA'
    ],
    floorplans: [ 0 ],
    floorplansName: [
      'Half Floor Apartment'
    ],
    floorplansUrl: [
      ''
    ]
  },

  middleAsset: {
    videos: [ 0, 1 ],
    videosName: [
      'Walkthrough',
      'Cinematic'
    ],
    panoramas: [ 0 ],
    panoramasName: [
      'Middle Apartment'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/40ARPkDyt'
    ],
    floorplans: [ 0 ],
    floorplansName: [
      'Middle Apartment'
    ],
    floorplansUrl: [
      ''
    ]
  },
  
  amenitiesAsset: {
    videos: [ 0, 1 ],
    videosName: [
      'Walkthrough',
      'Cinematic'
    ],
    panoramas: [ 0 ],
    panoramasName: [
      'Amenities'
    ],
    panoramasUrl: [
      'https://app.cloudpano.com/tours/j_J2cp4xK7J'
    ]
  },
    
  exteriorAsset: {
    videos: [ 0 ],
    videosName: [
      'Cinematic'
    ]
  },
});


export default { theme };