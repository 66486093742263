<template>
  
</template>

<script>
export default {
  name: "Flex",
  data() {
    return {

    }
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
};
</script>
