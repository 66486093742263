<template>
  <!-- Top navigation -->
  <DetailTopNav :heading="this.viewType" :subheading="this.assetType" />

  <!-- Range -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'renders' && this.assetTypesReady === true">
    <div class="col-12 h-100 p-0 z-1">
      <div class="row h-100 g-0">
        <div class="col-12 h-100 position-relative z-1">
          <img :src="theme.cdnUrl + '/assets/' + assetType + '/' + viewType + '/' + this.assetTypes[this.order].LinkName" class="h-100">
        </div>
      </div>
    </div>
  </div>

  <!-- floorplans -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'floorplans' && this.assetTypesReady === true">
    <div class="col-12 h-100 p-0 z-1">
      <div class="row h-100 g-0">
        <div class="col-12 h-100 position-relative z-1">
          <img :src="theme.cdnUrl + '/assets/' + assetType + '/' + viewType + '/' + this.assetTypes[this.order].LinkName" class="h-100">
        </div>
      </div>
    </div>
  </div>

  <!-- Panoramas -->
  <div class="position-absolute v-content d-flex align-items-center text-center z-1" v-if="viewType == 'panoramas' || viewType == 'virtual-tour' || viewType == 'balcony-views' && this.assetTypesReady === true">
    <iframe :src="this.assetTypes[this.order].url" :title="this.assetTypes[this.order].title" width="100%" height="100%"></iframe>
  </div>

  <OffCanvas />
</template>

<script>
import DetailTopNav from '@/components/DetailTopNav.vue'
import OffCanvas from "@/components/OffCanvas.vue"
import global from "../global"
import BunnyNetService from "@/services/BunnyNetService.js";
import { unslugify } from "unslugify";

export default {
  name: "AssetViewDetail",
  props: [
    'assetType',
    'viewType',
    'order'
  ],
  components: {
    DetailTopNav,
    OffCanvas
  },
  data() {
    return {
      assetTypes: null,
      assetOrder: null,
      currentAsset: null,
      folderStructure: encodeURIComponent(this.theme.devFolder + '/assets/' + this.assetType + '/' + this.viewType + '/'),
      currentIndex: null,
      assetNames: null,
      assetUrls: null,
      componentName: "AssetView",
      assetTypesReady: false
    }
  },
  created() {
    if (this.assetType === '3-bedroom-study-type-03') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.bed3Asset.videos
        this.assetNames = this.theme.bed3Asset.videosName
      } else if (this.viewType === 'virtual-tour') {
        this.assetOrder = this.theme.bed3Asset.panoramas
        this.assetNames = this.theme.bed3Asset.panoramasName
        this.assetUrls = this.theme.bed3Asset.panoramasUrl
      } else if (this.viewType === 'floorplans') {
        this.assetOrder = this.theme.bed3Asset.floorplans
        this.assetNames = this.theme.bed3Asset.floorplansName
        this.assetUrls = this.theme.bed3Asset.floorplansUrl
      }
    } else if (this.assetType === 'half-floor-apartment') {
        if (this.viewType === 'videos') {
            this.assetOrder = this.theme.halfAsset.videos
            this.assetNames = this.theme.halfAsset.videosName
        } else if (this.viewType === 'virtual-tour') {
            this.assetOrder = this.theme.halfAsset.panoramas
            this.assetNames = this.theme.halfAsset.panoramasName
            this.assetUrls = this.theme.halfAsset.panoramasUrl
        } else if (this.viewType === 'floorplans') {
            this.assetOrder = this.theme.halfAsset.floorplans
            this.assetNames = this.theme.halfAsset.floorplansName
            this.assetUrls = this.theme.halfAsset.floorplansUrl
        }
    } else if (this.assetType === 'middle-apartment') {
        if (this.viewType === 'videos') {
            this.assetOrder = this.theme.middleAsset.videos
            this.assetNames = this.theme.middleAsset.videosName
        } else if (this.viewType === 'virtual-tour') {
            this.assetOrder = this.theme.middleAsset.panoramas
            this.assetNames = this.theme.middleAsset.panoramasName
            this.assetUrls = this.theme.middleAsset.panoramasUrl
        } else if (this.viewType === 'floorplans') {
            this.assetOrder = this.theme.middleAsset.floorplans
            this.assetNames = this.theme.middleAsset.floorplansName
            this.assetUrls = this.theme.middleAsset.floorplansUrl
        }
    } else if (this.assetType === 'amenities') {
        if (this.viewType === 'videos') {
            this.assetOrder = this.theme.amenitiesAsset.videos
            this.assetNames = this.theme.amenitiesAsset.videosName
        } else if (this.viewType === 'virtual-tour') {
            this.assetOrder = this.theme.amenitiesAsset.panoramas
            this.assetNames = this.theme.amenitiesAsset.panoramasName
            this.assetUrls = this.theme.amenitiesAsset.panoramasUrl
        }
    } else if (this.assetType === 'exterior') {
      if (this.viewType === 'videos') {
        this.assetOrder = this.theme.exteriorAsset.videos
        this.assetNames = this.theme.amenitiesAsset.videosName
      }
    }

    BunnyNetService.getRenders(this.folderStructure, this.assetType)
    .then(response => {
      let j = 0;
      // Remap array
      const menuItems = response.data.map((item) => {
        return {
          Guid: item.Guid,
          ObjectName: unslugify(item.ObjectName),
          LinkName: item.ObjectName,
          Path: item.Path,
          IsDirectory: item.IsDirectory,
          StorageZoneName: item.StorageZoneName,
          title: this.assetNames[j],
          url: this.assetUrls[j],
          id: j++
        };
      });

      // Custom order array based on section and type
      if( this.assetOrder ) {
        this.assetTypes = this.reorder(menuItems, this.assetOrder);
      } else {
        this.assetTypes = menuItems
      }

      this.assetTypesReady = true
    })
  },
  methods: {
    // Reorder array based on config setup
    reorder: function(data_array, real_order) {
      var i, d = {}, result = [];
      for(i=0; i<data_array.length; ++i) {
        d[data_array[i].id] = data_array[i];
      }

      for(i=0; i<real_order.length; ++i) {
        result.push(d[real_order[i]]);
      }

      return result;
    },
  },
  setup () {
    const {theme} = global

    return {
      theme
    }
  }
}
</script>