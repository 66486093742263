<template>
  <div class="position-fixed v-header z-3">
    <div class="col-12">
      <div class="row">
        <div class="col-12 ps-4 pe-4 d-flex align-items-center justify-content-center v-theme-header">
          <router-link
            :to="{
                name:  this.componentName,
                params: {
                  assetType: this.subheading,
                  viewType: this.heading
                }
              }"
            class="btn-close-asset"
            v-if="this.viewType != 'floorplans'">
          </router-link>
          <router-link
            :to="{
                name:  this.componentName,
                params: {
                  assetType: this.subheading,
                  viewType: this.heading
                }
              }"
            class="btn-close-asset-rev"
            v-else>
          </router-link>
          <!-- <button @click="$router.go(-1)" class="btn-close-asset" v-if="this.viewType != 'floorplans'"></button>
          <button @click="$router.go(-1)" class="btn-close-asset-rev" v-if="this.viewType == 'floorplans'"></button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailTopNav',
  props: [
    'heading',
    'subheading'
  ],
  data() {
    return {
      componentName: "AssetView"
    }
  }
};
</script>